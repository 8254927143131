import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { ProcessMapDropdownShowMenuCallback } from './useProcessMapDropdown';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import Icons from 'ecto-common/lib/Icons/Icons';
import { beautifyEquipmentName } from '../utils/equipmentTypeUtils';

import useLatestSignalValues from 'ecto-common/lib/hooks/useLatestSignalValues';
import APIGen from 'ecto-common/lib/API/APIGen';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import { getLatestDataPoint } from 'ecto-common/lib/SignalsTable/signalsTableUtils';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import { getSignalTypeUnit } from 'ecto-common/lib/SignalSelector/SignalUtils';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import styles from './ProcessMapDropdown.module.css';

type LiveDataEntry = {
  signalId: string;
  name: string;
  subtitle: string;
  unit: string;
};
const NodeNavigationSignalPreviewHeader = ({
  signalIds
}: {
  signalIds: string[];
}) => {
  const liveSignalData = useLatestSignalValues(null, signalIds);

  const getSignalDataQuery = APIGen.Signals.getProvidersBySignalIds.useQuery({
    signalIds
  });

  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const signalTableData = useMemo(() => {
    if (getSignalDataQuery.data == null) {
      return [];
    }

    const allSignalTableData: LiveDataEntry[] = [];
    for (const provider of getSignalDataQuery.data) {
      for (const signal of provider.signals) {
        if (signalIds.includes(signal.signalId)) {
          allSignalTableData.push({
            signalId: signal.signalId,
            name: signal.name,
            subtitle: provider.signalProviderName,
            unit: getSignalTypeUnit(
              signal.signalTypeId,
              signalTypesMap,
              signalUnitTypesMap
            )
          });
        }
      }
    }

    return allSignalTableData;
  }, [getSignalDataQuery.data, signalIds, signalTypesMap, signalUnitTypesMap]);

  return (
    <div className={styles.linkHeader}>
      {getSignalDataQuery.isLoading && <Spinner size={SpinnerSize.TINY} />}

      {signalTableData.map((signal) => {
        const dataPoint = getLatestDataPoint(liveSignalData[signal.signalId]);

        return (
          <div className={styles.linkHeaderRow} key={signal.signalId}>
            <div style={{ flex: 1, minWidth: 200 }}>
              <Tooltip text={signal.name}>
                <TableColumn title={signal.name} subtitle={signal.subtitle} />
              </Tooltip>
            </div>
            <div className={styles.linkHeaderValue}>
              {formatNumberUnit(dataPoint?.value, signal.unit)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const useNodeNavigationLinkAction = ({
  showMenu,
  hideMenu,
  confirmNavigationToNodeId
}: {
  showMenu: ProcessMapDropdownShowMenuCallback;
  hideMenu: () => void;
  confirmNavigationToNodeId: (nodeId: string) => void;
}) => {
  const equipmentMap = useCommonSelector((state) => state.general.equipmentMap);
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const equipmentTypes = useCommonSelector(
    (state) => state.general.equipmentTypes
  );
  return useCallback(
    (event: MouseEvent, nodeId: string, previewSignalIds: string[]) => {
      const equipment = equipmentMap[nodeId];

      const node = nodeMap[nodeId] ?? equipment;

      let subtitle: string = null;

      if (equipment != null) {
        const eqType = equipmentTypes.find(
          (type) => type.equipmentTypeId === equipment.equipmentTypeId
        );
        subtitle = beautifyEquipmentName(eqType?.name);
      }

      const x = event.clientX;
      const y = event.clientY;

      showMenu(
        [
          {
            label: node?.name,
            icon: <Icons.ArrowRight />,
            subtitle,
            action: () => {
              hideMenu();
              confirmNavigationToNodeId(nodeId);
            }
          }
        ],
        {
          x,
          y
        },
        previewSignalIds.length > 0 ? (
          <NodeNavigationSignalPreviewHeader signalIds={previewSignalIds} />
        ) : null
      );
    },
    [
      equipmentMap,
      nodeMap,
      showMenu,
      equipmentTypes,
      hideMenu,
      confirmNavigationToNodeId
    ]
  );
};

export default useNodeNavigationLinkAction;
