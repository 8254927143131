import operator from './operator';
import schedule from '../modules/schedule/schedule';
import settings from './settings';
import signals from 'ecto-common/lib/modules/signals/signals';
import signalCollections from '../modules/signalCollections/signalCollections';
import general from 'ecto-common/lib/reducers/general';
import { CLEAR_REDUX_STATE } from 'ecto-common/lib/actions/actionTypes';
import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const appReducer = combineReducers({
  general,
  operator,
  schedule,
  settings,
  signals,
  signalCollections
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type OperatorRootState = ReturnType<typeof appReducer>;

const rootReducer: Reducer = (state: OperatorRootState, action) => {
  if (action.type === CLEAR_REDUX_STATE) {
    state = {} as OperatorRootState;
  }

  return appReducer(state, action as never);
};

const store = configureStore({
  reducer: rootReducer,
  // Disable as dev tools do not work well, and they slow everything down
  devTools: false,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    });
  }
});

export type OperatorGetState = () => OperatorRootState;
export const useOperatorSelector: TypedUseSelectorHook<OperatorRootState> =
  useSelector;
export type OperatorDispatch = typeof store.dispatch;
export const useOperatorDispatch: () => OperatorDispatch = useDispatch; // Export a hook that can be reused to resolve types

export default store;
