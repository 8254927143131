import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';

import {
  NodeResponseModel,
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import {
  LastSignalValuesDataSourceResult,
  SignalInputType
} from '../datasources/LastSignalValuesDataSource';
import { MatchedSignal } from '../datasources/SignalValuesDataSource';
import { EmptySignalType } from 'ecto-common/lib/utils/constants';
import TimeRangeContext, {
  TimeRangeContextType
} from '../context/TimeRangeContext';
import T from 'ecto-common/lib/lang/Language';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { getNodePage } from 'ecto-common/lib/utils/commonLinkUtil';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import CopyToClipboardTooltip from 'ecto-common/lib/CopyToClipboardTooltip/CopyToClipboardTooltip';
import { numDecimalsForUnit } from 'ecto-common/lib/Charts/UnitUtil';
import { formatNumber } from 'ecto-common/lib/utils/stringUtils';
import { getSignalTypeUnit } from 'ecto-common/lib/SignalSelector/SignalUtils';
import moment from 'moment';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import {
  TimeFormats,
  getDefaultDateTimeFormat
} from 'ecto-common/lib/utils/dateUtils';

const labelForSignalRule = (
  isLoading: boolean,
  matchingSignals: MatchedSignal[],
  signalRule: SignalInputType,
  signalTypesMap: Record<string, SignalTypeResponseModel>,
  signalUnitTypesMap: Record<string, UnitResponseModel>
) => {
  const matchingSignal = _.find(matchingSignals, [
    'signal.signalTypeId',
    signalRule.signalTypeId
  ])?.signal;

  if (isLoading) {
    return T.common.loading;
  }
  const signalType = signalTypesMap[signalRule.signalTypeId] ?? EmptySignalType;

  const unit =
    signalUnitTypesMap[signalTypesMap[matchingSignal?.signalTypeId]?.unitId]
      ?.unit;

  return (
    (signalRule.displayName ?? matchingSignal?.name ?? signalType.name) +
    ' (' +
    unit +
    ')'
  );
};

const NodeCell = ({ node }: { node: SingleGridNode }) => {
  const { tenantId } = useContext(TenantContext);
  return <NavLink to={getNodePage(tenantId, node)}>{node?.name}</NavLink>;
};

const SignalValueCell = ({
  node,
  signals,
  signalTypeId,
  signalValueMap
}: {
  node: SingleGridNode;
  signals: LastSignalValuesDataSourceResult;
  signalTypeId: string;
  signalValueMap: Record<
    string,
    { value: number; time?: string; step?: number }
  >;
}) => {
  const { signalTypesMap, signalUnitTypesMap } =
    useContext(DashboardDataContext);

  const selectedSignals = _.filter(signals?.signalInfo?.matchingSignals, [
    'signal.signalTypeId',
    signalTypeId
  ]);
  const signalInfo = signals?.signalInfo;

  const _selectedSignal = _.find(
    selectedSignals,
    ({ signal }) =>
      signalInfo?.nodeIdToSignal[node.nodeId]?.[signal.signalId] != null
  );
  const unit = getSignalTypeUnit(
    signalTypeId,
    signalTypesMap,
    signalUnitTypesMap
  );

  const signal = _selectedSignal?.signal;
  const signalValueInfo = signalValueMap[signal?.signalId];
  const valueStr = formatNumber(
    signalValueInfo?.value,
    numDecimalsForUnit(unit)
  );

  let tooltipText = null;
  if (signalValueInfo?.step || signalValueInfo?.time) {
    let timeVal = '-';

    if (signalValueInfo.time) {
      timeVal = moment(signalValueInfo.time)
        .tz(DEFAULT_TIMEZONE)
        .format(getDefaultDateTimeFormat(TimeFormats.SECONDS));
    }

    tooltipText = T.format(
      T.equipment.tooltipformat,
      <strong key="time-text">{timeVal} </strong>,
      <strong key="step-text">{signalValueInfo.step || '-'}</strong>
    );
  } else {
    tooltipText = T.equipment.novalueset;
  }

  return (
    <CopyToClipboardTooltip
      valueToCopy={'' + valueStr}
      additionalText={tooltipText}
    >
      {valueStr}
    </CopyToClipboardTooltip>
  );
};

const columns: DataTableColumnProps<SingleGridNode>[] = [
  {
    dataKey: '_node',
    label: T.common.name,
    dataFormatter: (_unused: object, node: SingleGridNode) => {
      return <NodeCell node={node} />;
    }
  }
];

const SignalsTable = ({
  nodeList,
  signals
}: {
  /**
   * An array of nodes that will be listed with the signal values
   */
  nodeList: NodeResponseModel[];
  /**
   * A object with collected signal information from the selected signals
   */
  signals: LastSignalValuesDataSourceResult;
}) => {
  const timeRange = useContext<TimeRangeContextType>(TimeRangeContext);
  const timeRangeOption = timeRange?.timeRangeOption;

  const { signalTypesMap, signalUnitTypesMap } =
    useContext(DashboardDataContext);

  const signalValueMap = _.keyBy(signals?.signalValues, 'signalId');
  const { items, columns: _columns } = useMemo(() => {
    const inputsFilteredByTimeOption = _.filter(
      signals?.signalInfo?.signalInputs,
      (input) => {
        if (input?.timeRange) {
          return input.timeRange === timeRangeOption;
        }

        return input != null;
      }
    );

    const newColumns = _.map(inputsFilteredByTimeOption, (signal) => ({
      dataKey: signal.signalTypeId,
      label: labelForSignalRule(
        signals?.isLoading,
        signals?.signalInfo?.matchingSignals,
        signal,
        signalTypesMap,
        signalUnitTypesMap
      ),
      dataFormatter: (_unused: string, node: SingleGridNode) => {
        return (
          <SignalValueCell
            node={node}
            signalTypeId={signal.signalTypeId}
            signals={signals}
            signalValueMap={signalValueMap}
          />
        );
      }
    }));

    return {
      items: nodeList,
      columns: [...columns, ...newColumns]
    };
  }, [
    signals,
    nodeList,
    timeRangeOption,
    signalTypesMap,
    signalUnitTypesMap,
    signalValueMap
  ]);

  return <DataTable data={items} columns={_columns} />;
};

export default SignalsTable;
