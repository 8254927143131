import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';
import localStore from 'store';
import { useNavigate, useParams } from 'react-router-dom';

import Dashboard from 'ecto-common/lib/Dashboard/Dashboard';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import HelpPaths from 'ecto-common/help/tocKeys';
import DashboardDataContext, {
  useDashboardDataFromRedux
} from 'ecto-common/lib/hooks/DashboardDataContext';
import { DASHBOARD_LOCAL_STORAGE_KEY } from 'ecto-common/lib/utils/persistentNodeState';
import CollapsingSegmentControlPicker, {
  OptionWithIcon
} from 'ecto-common/lib/SegmentControl/CollapsingSegmentControlPicker';
import TimeRangeContext from 'ecto-common/lib/Dashboard/context/TimeRangeContext';
import useTimeRangeSelector from 'ecto-common/lib/Dashboard/context/useTimeRangeSelector';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';

import DashboardCopyPanelToPersonalModal from 'js/components/DashboardPage/DashboardCopyPanelToPersonalModal';
import useDashboardMenu from 'js/components/DashboardPage/useDashboardMenu';
import styles from './DashboardPage.module.css';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { DashboardPanel } from 'ecto-common/lib/Dashboard/Panel';
import { DashboardFile } from 'ecto-common/lib/DashboardEditor/DashboardConstants';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';
import PresentationAPIGen from 'ecto-common/lib/API/PresentationAPIGen';
import { applyDashboardMigrationsToCollection } from 'ecto-common/lib/Dashboard/migrations/migrations';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import { usePresentationItemQuery } from 'ecto-common/lib/utils/presentationLibrary';

// Store which dashboard was last showing for a given collection to make navigation easier
const updateLastDashboardIndex = (dashboardIndex: number) => {
  localStore.set(DASHBOARD_LOCAL_STORAGE_KEY, dashboardIndex);
};

const DashboardPage = ({
  onTitleChanged
}: {
  onTitleChanged: (title: string[]) => void;
}) => {
  const params = useParams<NodeParams>();
  const nodeId = params.nodeId;
  const { tenantId } = useContext(TenantContext);

  const navigate = useNavigate();
  const selectedDashboardIndex = _.parseInt(params.subPage ?? '0') ?? 0;
  const setSelectedDashboardIndex = useCallback(
    (newIndex: number) => {
      navigate(`/${tenantId}/home/${nodeId}/dashboard/${newIndex}`);
    },
    [navigate, nodeId, tenantId]
  );

  const setNode = useCallback(
    (newNodeId: string) => {
      navigate(
        `/${tenantId}/home/${newNodeId}/dashboard/${selectedDashboardIndex}`
      );
    },
    [navigate, selectedDashboardIndex, tenantId]
  );

  const urlBuilder = useCallback(
    (newTenantId: string, newNodeId: string, _equipmentId: string) => {
      return `/${newTenantId}/home/${newNodeId}/dashboard/${selectedDashboardIndex}`;
    },
    [selectedDashboardIndex]
  );

  const reduxDashboardDataValue = useDashboardDataFromRedux();
  const dashboardDataValue = useMemo(
    () => ({
      nodeId,
      setNode,
      ...reduxDashboardDataValue
    }),
    [nodeId, reduxDashboardDataValue, setNode]
  );

  const [addToPersonalPanelId, setAddToPersonalPanelId] = useSearchParamState(
    'add-to-personal-panel-id',
    null
  );

  const setAddToPersonalPanel = useCallback(
    (panel: DashboardPanel) => {
      setAddToPersonalPanelId(panel?.id);
    },
    [setAddToPersonalPanelId]
  );

  const hideAddToPersonalDialog = useCallback(
    () => setAddToPersonalPanelId(null),
    [setAddToPersonalPanelId]
  );

  useEffect(() => {
    updateLastDashboardIndex(selectedDashboardIndex);
  }, [selectedDashboardIndex]);

  const enums = useOperatorSelector((state) => state.general.enums);
  const signalTypesNameMap = useOperatorSelector(
    (state) => state.general.signalTypesNameMap
  );

  const { query: dashboardsQuery } = usePresentationItemQuery({
    queryHook: PresentationAPIGen.Nodes.getDashboards.useQuery,
    nodeId
  });

  const dashboards = useMemo(() => {
    const parsedItems = _.map(dashboardsQuery.data.items, (item) => {
      if (!_.isEmpty(item.data)) {
        try {
          return {
            ...item,
            data: JSON.parse(item.data) as DashboardFile
          };
        } catch (e) {
          console.error(e);
        }
      }
      return item;
    }) as { name?: string; data: DashboardFile }[];

    return applyDashboardMigrationsToCollection(
      { ...dashboardsQuery.data, items: parsedItems },
      enums,
      signalTypesNameMap
    );
  }, [dashboardsQuery.data, enums, signalTypesNameMap]);

  type DashboardInfo = { name?: string; data: DashboardFile };

  const selectOptions: OptionWithIcon<DashboardInfo>[] = useMemo(() => {
    return _.map(dashboards.items, (dashboard) => ({
      label: dashboard.name,
      value: dashboard
    }));
  }, [dashboards.items]);

  const onChangeDashboard = useCallback(
    (option: OptionWithIcon<DashboardInfo>) => {
      let index = _.indexOf(selectOptions, option);
      index = index === -1 ? 0 : index;
      setSelectedDashboardIndex(index);
      updateLastDashboardIndex(index);
    },
    [selectOptions, setSelectedDashboardIndex]
  );

  const selectedOption =
    selectOptions[selectedDashboardIndex] ?? _.head(selectOptions);

  const [timeRange, timeRangeComponent] = useTimeRangeSelector();

  const toolbarItems = useMemo(() => {
    return (
      <>
        <ToolbarItem expanding>
          {selectOptions.length > 1 && (
            <CollapsingSegmentControlPicker
              options={selectOptions}
              value={selectedOption}
              onChangeValue={onChangeDashboard}
            />
          )}
        </ToolbarItem>
        <ToolbarItem className={styles.timerange}>
          {timeRangeComponent}
        </ToolbarItem>
      </>
    );
  }, [onChangeDashboard, selectOptions, selectedOption, timeRangeComponent]);

  const selectedDashboard = selectOptions[selectedDashboardIndex]?.value;
  const dashboardData = selectedDashboard?.data;
  const error = dashboardsQuery.isError ? T.common.error : null;
  const { menuOptions, menuComponent } = useDashboardMenu({
    onAddToPersonal: setAddToPersonalPanel
  });

  const addToPersonalPanel = dashboardData?.panels?.find(
    (panel) => panel.id === addToPersonalPanelId
  );

  usePageTitleCallback({
    mainTitle: T.location.tabs.dashboard,
    subTitle: selectedDashboard?.name ?? '',
    onTitleChanged
  });

  return (
    <ToolbarContentPage
      title={T.location.tabs.dashboard}
      wrapContent={false}
      toolbarItems={toolbarItems}
      urlBuilder={urlBuilder}
      helpPath={HelpPaths.docs.operator.dashboard}
    >
      {dashboardsQuery.isPending && selectedDashboard == null && (
        <LoadingContainer isLoading showSpinner />
      )}

      {error && <ErrorNotice>{error}</ErrorNotice>}

      {selectedDashboard && selectedDashboard.data && !error && (
        <DashboardDataContext.Provider value={dashboardDataValue}>
          <TimeRangeContext.Provider value={timeRange}>
            <Dashboard
              responsive
              data={dashboardData}
              isResizable={false}
              isDraggable={false}
              menuOptions={menuOptions}
            />
            <DashboardCopyPanelToPersonalModal
              panel={addToPersonalPanel}
              isOpen={addToPersonalPanel != null}
              onModalClose={hideAddToPersonalDialog}
            />
          </TimeRangeContext.Provider>
        </DashboardDataContext.Provider>
      )}

      {!dashboardsQuery.isFetching &&
        _.isEmpty(dashboardsQuery.data?.items) && (
          <NoDataMessage
            title={T.dashboard.error.nodashboardsfound}
            message={null}
          />
        )}

      {menuComponent}
    </ToolbarContentPage>
  );
};

export default React.memo(DashboardPage);
