const ModelType = {
  NODE: 'Node',
  NODE_LIST: 'NodeList',
  TEXT: 'Text',
  DISABLED_TEXT: 'DisabledText',
  COLOR: 'Color',
  NUMBER: 'Number',
  BOOL: 'Bool',
  LABEL: 'Label',
  BUTTON: 'Button',
  TEXT_ACTION: 'TextAction',
  OPTIONS: 'Options',
  ODATA_LIST_OPTIONS: 'OdataListOptions',
  TABLE: 'Table',
  RADIO: 'Radio',
  SIGNAL: 'Signal',
  SIGNAL_LIST: 'SignalList',
  SIGNAL_TYPE: 'SignalType',
  EQUIPMENT: 'Equipment',
  SPACE: 'Space',
  CHECKBOX_OPTIONS: 'CheckboxOptions',
  DATE: 'Date',
  CUSTOM: 'Custom'
} as const;

export type ModelTypes = (typeof ModelType)[keyof typeof ModelType];

export default ModelType;
